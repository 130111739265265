export function loadMenu(UI, content, lang) {
    if (!UI) throw new Error('UI param is missing');
    if (!content) throw new Error('content param is missing');
    if (!lang) throw new Error('lang param is missing');
      Array.from(UI.navBar.children).forEach((navItem, index) => {
          if (!navItem.classList.contains('social')) {
              navItem.querySelectorAll(':not(.social)')[0].textContent =
                  content.menu[lang][index];
          }
      });
}