// Import Images
import '../img/*.jpg';
import '../img/*.png';
import '../img/logos/*.png';
import '../img/team/*.png';

// Import documents
import '../docs/Toolkit-DreamBIG-Online.pdf';

// Import vendor JS
var jquery = require("jquery");
window.$ = window.jQuery = jquery;
import '../vendor/bootstrap/js/bootstrap.min.js';
import '../vendor/jquery-easing/jquery.easing';
import '../vendor/popper/popper.min.js';
import agency from '../vendor/agency';


// Import common JS
import contactMe from './dom/contact_me';
import './utils/main';

contactMe($);
agency($);


// Import Vendor CSS
import '../vendor/bootstrap/css/bootstrap.min.css';
import '../vendor/fontawesome-free/css/all.min.css';

// Import main css
import '../css/agency.css';


document.addEventListener("DOMContentLoaded", adjustEditionsContainerHeight);
window.addEventListener("resize", adjustEditionsContainerHeight);
window.addEventListener("deviceorientation", adjustEditionsContainerHeight);


function adjustEditionsContainerHeight() {
    const itemsEditions = document.querySelector("#editions .container.items");
    itemsEditions.parentNode.style.height = itemsEditions.clientHeight - 115 + "px";
}