const langId = 'langPreferenceDreambig-be4c86dac0eae1a1319841afbc323a24';
let lang = getLangPreference();

function getLangPreference() {
    let lang = getUserBrowserLanguage();
    if (typeof Storage !== 'undefined' && localStorage.getItem(langId)) {
        return localStorage.getItem(langId);
    }
    return lang;
}

function getUserBrowserLanguage() {
    let lang = window.navigator.language || 'es';
    if (lang.length > 2) {
        lang = lang.split('-')[0];
    }
    return lang;
}

function saveLangPreference(lang) {
    if (typeof Storage !== 'undefined') {
        localStorage.setItem(langId, lang);
    }
}

module.exports = {
    getLangPreference,
    getUserBrowserLanguage,
    saveLangPreference
};