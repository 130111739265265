import amposta from "../../img/portfolio/amposta/*.jpg";
import amposta2019 from "../../img/portfolio/amposta_2019/*.jpg";
import ifest2016 from "../../img/portfolio/ifest_2016/*.jpg";
import ifest2017 from "../../img/portfolio/ifest_2017/*.jpg";
import uabEmpren from "../../img/portfolio/uab/*.jpg";
import tenerifeIntech from "../../img/portfolio/tenerife_intech/*.jpg";
import klm from "../../img/portfolio/klm/*.jpg";
import malaga from "../../img/portfolio/malaga/*.jpg";
import olot from "../../img/portfolio/olot/*.jpg";
import nespresso from "../../img/portfolio/nespresso/*.jpg";
import bucaramanga from "../../img/portfolio/bucaramanga/*.jpg";
import fpdgi from "../../img/portfolio/fpdg/*.jpg";
import margarita from "../../img/portfolio/margarita/*.jpg";
import asturias2019 from "../../img/portfolio/asturias_2019/*.jpg";
import imagine_circular_economy2019 from "../../img/portfolio/circular_economy_2019/*.jpg";
import dreambig_big_esport2019 from "../../img/portfolio/dream_big_esport_2019/*.jpeg";
import dreambig_big_hola_luz2020 from "../../img/portfolio/hola-luz-2020/*.jpg";
import dreambig_big_novartis2020 from "../../img/portfolio/novartis-2020/*.jpg";
import dreambig_big_express2020 from "../../img/portfolio/dreambig-express-2020/*.jpeg";

const imagesList = [
    {
        id: "dreambig_big_express2020",
        list: Object.values(dreambig_big_express2020)
    },
    {
        id: "dreambig_big_novartis2020",
        list: Object.values(dreambig_big_novartis2020)
    },
    {
        id: "dreambig_big_hola_luz2020",
        list: Object.values(dreambig_big_hola_luz2020)
    },
    {
        id: "dreambig_big_esport2019",
        list: Object.values(dreambig_big_esport2019)
    },
    {
        id: "imagine_circular_economy2019",
        list: Object.values(imagine_circular_economy2019)
    },
    {
        id: 'ifest2016',
        list: Object.values(ifest2016)
    },
    {
        id: 'ifest2017',
        list: Object.values(ifest2017)
    },
    {
        id: 'amposta',
        list: Object.values(amposta)
    },
    {
        id: 'amposta2019',
        list: Object.values(amposta2019)
    },
    {
        id: 'uabEmpren',
        list: Object.values(uabEmpren)
    },
    {
        id: 'tenerifeIntech',
        list: Object.values(tenerifeIntech)
    },
    {
        id: 'klm',
        list: Object.values(klm)
    },
    {
        id: 'malaga',
        list: Object.values(malaga)
    },
    {
        id: 'olot',
        list: Object.values(olot)

    },
    {
        id: 'nespresso',
        list: Object.values(nespresso)
    },
    {
        id: 'bucaramanga',
        list: Object.values(bucaramanga)
    },
    {
        id: 'fpdgi',
        list: Object.values(fpdgi)
    },
    {
        id: 'margarita',
        list: Object.values(margarita)
    },
    {
        id: 'asturias2019',
        list: Object.values(asturias2019)
    }
];

export default imagesList;