import { content } from "../data/content";
import { getLangPreference } from "../utils/getLanguage";
const contactMe = function ($) {

  /*When clicking on Full hide fail/success boxes */
  $('#name').focus(function () {
    $('#success').html('');
  });

  document.getElementById("contactForm").addEventListener("submit", event => {
    const $form = event.currentTarget;
    event.preventDefault();
    $.ajax({
      url: "./mail/FormMail.php",
      type: "POST",
      data: $('#contactForm').serialize(),
      cache: false,
      success: function () {
        // Success message
        $('#success').html("<div class='alert alert-success'>");
        $('#success > .alert-success').html(
            "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;"
          )
          .append("</button>");
        $('#success > .alert-success')
          .append(`<strong>${content.form.submit.success[getLangPreference()]}</strong>`);
        $('#success > .alert-success')
          .append('</div>');
        //clear all fields
        $('#contactForm').trigger("reset");
      },
      error: function (err) {
        // Fail message
        $('#success').html("<div class='alert alert-danger'>");
        $('#success > .alert-danger').html(
            "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;"
          )
          .append("</button>");
        $('#success > .alert-danger').append($("<strong>").text(
          `${content.form.submit.error[getLangPreference()]}`));
        $('#success > .alert-danger').append('</div>');
        //clear all fields
        $('#contactForm').trigger("reset");
      },
      complete: function () {
        setTimeout(function () {
          $($form).prop("disabled",
            false); // Re-enable submit button when AJAX call is complete
        }, 1000);
      }
    });
  });

  $("a[data-toggle=\"tab\"]").click(function (e) {
    e.preventDefault();
    $(this).tab("show");
  });
};

module.exports = contactMe;