import {
  content
} from "../data/content";

import {
  UI
} from "../dom/UISelector";

import { loadMenu } from "./commons";

import imageList from "./imageList";

import {
  getLangPreference,
  saveLangPreference
} from "./getLanguage";

let lang = getLangPreference();
document.addEventListener('DOMContentLoaded', loadEverything, false);

function loadEverything() {
  loadContent();
  loadGallery();
  setTimeout(() => {
    UI.languageSelector.style.display = 'block';
    Array.from(UI.languageSelector.children).forEach(option => {
      if (option.value === lang) {
        option.setAttribute('selected', '');
      }
    });
    UI.languageSelector.addEventListener(
      'change',
      changeLanguageDisplay,
      false
    );
  }, 2000);
}

function changeLanguageDisplay(e) {
  lang = e.target.value;
  saveLangPreference(lang);
  loadContent();
}

function loadContent() {
  // Load Menu
  loadMenu(UI, content, lang);
  // Load Main Header Description Dreambig
  UI.dreambig.innerHTML = '';
  const firstParagraph = document.createElement('p'); {
    const span = document.createElement('span');
    span.classList.add('font-black');
    span.textContent = content.dreambig[lang].hightlight;
    firstParagraph.appendChild(span);
    const textNode = document.createTextNode(
      ` ${content.dreambig[lang].description}`
    );
    firstParagraph.append(textNode);
  }
  const secondParagraph = document.createElement('p');
  secondParagraph.textContent = `${content.dreambig[lang].secondParagraph}`;
  const thirdParagraph = document.createElement('p');
  thirdParagraph.textContent = `${content.dreambig[lang].thirdParagraph}`;
  const noLimitsParagraph = document.createElement('p');
  noLimitsParagraph.classList.add('no-limit-text'); {
    const noLimitText = document.createTextNode(`${content.noLimits}`);
    noLimitsParagraph.append(noLimitText);
    const span = document.createElement('span');
    span.textContent = content.noLimitsEnding;
    noLimitsParagraph.appendChild(span);
  }
  UI.dreambig.appendChild(firstParagraph);
  UI.dreambig.appendChild(secondParagraph);
  UI.dreambig.appendChild(thirdParagraph);
  UI.dreambig.appendChild(noLimitsParagraph);

  // The program
  UI.theProgram.innerHTML = '';
  for (const paragraph in content.program[lang]) {
    if (content.program[lang].hasOwnProperty(paragraph)) {
      const text = content.program[lang][paragraph];
      const p = document.createElement('p');
      p.innerHTML = text;
      UI.theProgram.appendChild(p);
    }
  }

  // Next dreambig
  UI.nextDreambig.textContent = content.nextEditions[lang];

  // Next edition
  UI.nextEdition.forEach($item => ($item.textContent = content.nextEdition[lang]));

  // Next edition dates
  UI.nextEditionDates.forEach($date => {
    const date = JSON.parse($date.dataset.translateNextDreambigDates);
    $date.textContent = date[lang];
  });

  // Gallery
  UI.galleryText.forEach(span => (span.textContent = content.gallery[lang]));

  // Video
  UI.seeVideo.forEach(span => (span.textContent = content.seeVideo[lang]));

  // participants-text
  UI.participants.forEach(
    span => (span.textContent = content.participants[lang])
  );

  // More than
  UI.moreThan.forEach(span => span.textContent = content.moreThan[lang]);

  // Oviedo Date
  // UI.oviedoDate.textContent = content.oviedoDate[lang];

  // Amposta Date
  // UI.ampostaDate.textContent = content.ampostaDate[lang];

  // Sign up here
  UI.signUpHere.forEach(span => {
    span.textContent = content.signUpHere[lang];
  });

  // Know more
  UI.register.forEach($item => $item.textContent = content.register[lang]);

  // team
  UI.xaviInfoText.textContent = content.team.xavi[lang];
  UI.pauInfoText.textContent = content.team.pau[lang];
  UI.marcInfoText.innerHTML = content.team.marc[lang];

  // Contact
  UI.organizeDreambig.innerHTML = content.dreambig.question[lang];

  // Reset Form Messages
  resetFormMessage();

  // Form Placeholders
  UI.inputName.setAttribute('placeholder', content.form.name.placeholder[lang]);
  UI.inputEmail.setAttribute(
    'placeholder',
    content.form.email.placeholder[lang]
  );
  UI.inputPhone.setAttribute(
    'placeholder',
    content.form.phone.placeholder[lang]
  );
  UI.inputCountry.setAttribute(
    'placeholder',
    content.form.country.placeholder[lang]
  );
  UI.inputCompany.setAttribute(
    'placeholder',
    content.form.company.placeholder[lang]
  );

  // Submit Button text
  UI.sendMessageButton.textContent = content.form.submit.text[lang];

  // Form Required Input Error Messages
  UI.inputName.setAttribute(
    'data-validation-required-message',
    content.form.name.inputRequiredErrorMessage[lang]
  );
  UI.inputEmail.setAttribute(
    'data-validation-required-message',
    content.form.email.inputRequiredErrorMessage[lang]
  );
  UI.inputPhone.setAttribute(
    'data-validation-required-message',
    content.form.phone.inputRequiredErrorMessage[lang]
  );
  UI.inputCountry.setAttribute(
    'data-validation-required-message',
    content.form.country.inputRequiredErrorMessage[lang]
  );

  // Form Invalid Input Error Message
  UI.inputEmail.setAttribute(
    'data-validation-validemail-message',
    content.form.email.inputInvalidErrorMessage[lang]
  );
}

function resetFormMessage() {
  // Form Placeholders
  UI.inputName.setAttribute('placeholder', '');
  UI.inputEmail.setAttribute('placeholder', '');
  UI.inputPhone.setAttribute('placeholder', '');
  UI.inputCountry.setAttribute('placeholder', '');
  UI.inputCompany.setAttribute('placeholder', '');

  // Submit Button text

  // Form Required Input Error Messages
  UI.inputName.removeAttribute('data-validation-required-message');
  UI.inputEmail.removeAttribute('data-validation-required-message');
  UI.inputPhone.removeAttribute('data-validation-required-message');
  UI.inputCountry.removeAttribute('data-validation-required-message');

  // Form Invalid Input Error Message
  UI.inputEmail.removeAttribute('data-validation-validemail-message');
}

function loadGallery() {
  const galleryButtons = document.querySelectorAll(
    '.actions-buttons .gallery button'
  );
  const videoButtons = document.querySelectorAll(
    '.actions-buttons .play button'
  );
  const videoContainer = document.querySelector('.embed-container.video');
  const closeModalVideo = document.querySelector('.close-modal-video');
  const close = document.querySelector('.close-modal .close');
  const contentModal = document.querySelector('.modal-body');
  const galleryModalImages = document.createElement('div');
  const currentImage = document.createElement('img');
  const prev = document.querySelector('.modal-body .prev');
  const next = document.querySelector('.modal-body .next');
  let currentIndex = 0;
  let totalImages = 0;
  let imagesToShow = null;

  currentImage.classList.add('current-image');
  galleryButtons.forEach(galleryButton =>
    galleryButton.addEventListener('click', loadModalImage, false)
  );

  videoButtons.forEach(videoButton =>
    videoButton.addEventListener('click', loadVideo, false)
  );
  closeModalVideo.addEventListener('click', closeModalVideoHandler, false);
  galleryModalImages.classList.add('gallery-images');
  close.addEventListener(
    'click',
    function closeModal(e) {
      Array.from(contentModal.childNodes).forEach(img => {
        if (img.nodeName === 'IMG') {
          contentModal.removeChild(img);
        }
      });
      Array.from(galleryModalImages.childNodes).forEach(img =>
        galleryModalImages.removeChild(img)
      );
      currentIndex = 0;
      totalImages = 0;
    },
    false
  );

  document.addEventListener(
    'keydown',
    function handleArrowKeys(e) {
      if (
        e.keyCode === 37 &&
        (e.code === 'ArrowLeft' || e.key === 'ArrowLeft')
      ) {
        previousImage();
      } else if (
        e.keyCode === 39 &&
        (e.code === 'ArrowRight' || e.key === 'ArrowRight')
      ) {
        nextImage();
      }
    },
    false
  );

  galleryModalImages.addEventListener(
    'click',
    function changeCurrentImage(e) {
      if (e.target.tagName === 'IMG') {
        currentIndex = e.target.id;
        setCurrentImage();
      }
    },
    false
  );

  next.addEventListener('click', e => {
    nextImage();
  });

  prev.addEventListener('click', e => {
    previousImage();
  });

  function loadModalImage(e) {
    let id = '';
    if (e.target.nodeName === 'BUTTON') {
      id = e.target.id;
    } else {
      id = e.target.parentNode.id;
    }
    imagesToShow = imageList.find(item => item.id === id).list;
    const imagesInCurrentView = getImagesInView(imagesToShow, id);
    renderGridImages();
  }

  function renderGridImages() {
    imagesToShow.forEach((image, indexImage) => {
      const img = document.createElement('img');
      img.src = normalizeImageUrl(imagesToShow[indexImage]);
      img.id = indexImage;
      img.classList.add('img-gallery-thumbnail');
      galleryModalImages.appendChild(img);
    });
    totalImages = galleryModalImages.children.length;
    setCurrentImage();
    contentModal.appendChild(currentImage);
    contentModal.appendChild(galleryModalImages);
  }

  // TODO Get only the 4 images
  function getImagesInView(arrayOfImages, id) {
    /*     if (i < maxImages) {
          maxImages = maxImages + parseInt(maxImages / arrayOfImages.length);
          return arrayOfImages.slice(index, maxImages);
        } */
  }

  function setCurrentImage() {
    const imagesInCurrentView = getImagesInView(imagesToShow, currentIndex);
    currentImage.src = normalizeImageUrl(
      galleryModalImages.children[currentIndex].src || imagesToShow[0]
    );
    focusOnCurrentImageThumbnail();
    currentImage.id = currentIndex;
  }

  function previousImage() {
    if (currentIndex - 1 > 0) {
      currentIndex--;
      setCurrentImage();
    } else {
      currentIndex = 0;
      setCurrentImage();
    }
  }

  function focusOnCurrentImageThumbnail() {
    Array.from(galleryModalImages.children).forEach(imgChild => {
      if (imgChild.classList.contains('focus')) {
        imgChild.classList.remove('focus');
      }
    });
    galleryModalImages.children[currentIndex].classList.add('focus');
  }

  function nextImage() {
    if (+currentIndex + 1 < totalImages) {
      currentIndex++;
      setCurrentImage();
    }
  }

  function normalizeImageUrl(src) {
    return `${src}`;
  }

  function loadVideo(e) {
    const targetElement = e.target;
    const videoId = getVideoId(targetElement);

    const videoProvider = getVideoProvider(targetElement);

    if (videoProvider === 'youtube') {
      videoContainer.innerHTML =
        `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}"` +
        `frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    } else if (videoProvider === 'vimeo') {
      videoContainer.innerHTML = `<iframe src="https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0" frameborder='0'webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>`;
    }
  }

  function getVideoId(element) {
    if (element.nodeName === 'IMG') {
      return element.parentNode.dataset.id;
    }

    return element.dataset.id;
  }

  function getVideoProvider(element) {
    if (element.nodeName === 'IMG') {
      return element.parentNode.dataset.provider;
    }

    return element.dataset.provider;
  }

  function closeModalVideoHandler() {
    videoContainer.innerHTML = '';
  }
}