export const content = (function CONTENT() {
    return {
        menu: {
            es: [
                '¿Que es el DreamBIG?',
                'El programa',
                'Próximas DreamBIG',
                'Ediciones anteriores',
                'Promotores',
                'Organiza un DreamBIG'
            ],
            en: [
                'What is the DreamBIG?',
                'The program',
                'Next DreamBIG',
                'Previous editions',
                'Promoters',
                'Organize a DreamBIG'
            ],
            ca: [
                'Què és el DreamBIG?',
                'El programa',
                'Propers DreamBIG',
                'Edicions anteriors',
                'Promotors',
                'Organitza un DreamBIG'
            ]
        },
        signUpHere: {
            es: 'Inscríbete aquí',
            en: 'Sign up here',
            ca: 'Inscriu-te aquí'
        },
        next: {
            es: 'PRÓXIMAS',
            en: 'NEXT',
            ca: 'PROPERS'
        },
        nextEditions: {
            es: "Próximas ediciones",
            en: "Next editions",
            ca: "Propers edicions"
        },
        nextEdition: {
            es: "Próxima edición",
            en: "Next edition",
            ca: "Proper edició"
        },
        dreambig: {
            es: {
                hightlight: 'Dream Big Challenge',
                description: 'es una iniciativa que quiere poner en valor el talento de los jóvenes para construir el futuro en el que van a vivir.',
                secondParagraph: `Esta iniciativa se desarrolla a través de una 
        competición de innovación disruptiva donde se generan miles de soluciones a retos empresariales.`,
                thirdParagraph: `Un movimiento masivo para acercar los jóvenes a la realidad de la innovación en las empresas y empoderarles para que sean capaces de definir y hacer realidad el futuro en el que sueñan.`
            },
            en: {
                hightlight: 'Dream Big Challenge',
                description: 'is an initiative that aims to give value to the youth talent in order to build the future they will be living.',
                secondParagraph: `This initiative unfolds throughout a disruptive innovation competition where thousands of solutions for businesses challenges are developed.`,
                thirdParagraph: `It is a massive movement to close the gap between the youth and the real innovation in businesses and to empower them so they are capable of defining and making a reality of the future they dream.`
            },
            ca: {
                hightlight: 'Dream Big Challenge',
                description: 'és una iniciativa que vol posar en valor el talent dels joves per construir el futur en el que viuran.',
                secondParagraph: `Aquesta iniciativa es desenvolupa a través d’una competició d’innovació disruptiva on es generen milers de solucions a reptes empresarials.`,
                thirdParagraph: `Un moviment massiu per apropar els joves a la realitat de la innovació a les empreses i empoderar-los perquè siguin capaços de definir i fer realitat el futur en el qual somien.`
            },
            question: {
                es: `<span class="organize-dreambig-question">¿Quieres organizar </span>
                <span>un <span class="text-primary font-black">DreamBIG</span> en tu</span>
                <span>empresa o ciudad?</span>`,
                en: `<span class="organize-dreambig-question">Do you want to host </span>
                <span>a <span class="text-primary font-black">DreamBIG</span> in your</span>
                <span>business or city?</span>`,
                ca: `<span class="organize-dreambig-question">Vols organitzar </span>
                <span>un <span class="text-primary font-black">DreamBIG</span> a la teva</span>
                <span>empresa o ciutat?</span>`
            }
        },
        noLimits: 'THERE ARE NO LIMITS',
        noLimitsEnding: 'TO DREAM BIG',
        program: {
            es: {
                firstParagraph: `El programa contempla un evento de 3 horas que se desarrolla en pabellones y otros espacios de diferentes ciudades del mundo, con seguimiento de los mejores proyectos en variedad de aceleraciones, entre ellas un mes en Silicon Valley u otros premios.`,
                secondParagraph: `Los estudiantes trabajarán en equipos siguiendo el <a href="https://imagine.cc/who-we-are/#steps" target="_blank">Método Lombard</a>, el proceso de aprendizaje radical de <a href="http://imagine.cc" target="_blank">Imagine Creativity Center</a> basado en principios de Design Thinking y <a href="http://imaginedesigndoing.com" target="_blank">Design Doing</a>.`,
                thirdParagraph: `Cada evento contará al final del acto con un equipo ganador y algunos equipos finalistas.
Un jurado será el encargado de seleccionar las mejores ideas.`,
                fourthParagraph: `Los finalistas pueden acelerar su proyecto en ediciones de Imagine 
        que se desarrollarán por el mundo. Una de las aceleraciones con más repercusión es el Imagine Silicon Valley, donde representantes del mejor equipo de cada edición 
        tendrá la oportunidad de participar en un programa de aceleración de 1 un mes en Silicon Valley, y desarrollar y hacer realidad su idea. Este programa consistirá en una serie de visitas al ecosistema único de Silicon Valley, conectando e interactuando con clientes potenciales y visitando las empresas más innovadoras del mundo, inversores, socios estratégicos y expertos locales que les aportarán una experiencia transformadora.`
            },
            en: {
                firstParagraph: `The program offers a 3 hours event that take place in pavilions and other spaces in cities around the world, that culminate with various acceleration programs for the best projects, being one of them a month in Silicon Valley or others awards.`,
                secondParagraph: `The students will work in teams following the <a href="https://imagine.cc/who-we-are/#steps" target="_blank">Lombard Method</a>, the radical learning process from <a href="http://imagine.cc" target="_blank">Imagine Creativity Center</a> based on the principles of Design Thinking and <a href="http://imaginedesigndoing.com" target="_blank">Design Doing</a>.`,
                thirdParagraph: `Every event ends with one winning team and some finalist teams.
A jury is in charge of selecting the best ideas.`,
                fourthParagraph: `The finalists can accelerate their projects in Imagine editions that take place around the world.
         Imagine Silicon Valley is one of the acceleration programs with the most impact so far. 
         The best teams from previous editions are given the opportunity to participate in this monthly program in the Bay Area and further develop their idea to become true. This program offers a wide variety of visits in the unique Silicon Valley ecosystem, connecting and interacting with potential clients and visiting the most innovative businesses of the world, investors, strategic partners and local experts that will enrich the participants transformative experience.`
            },
            ca: {
                firstParagraph: `El programa contempla un esdeveniment de 3 hores que es desenvolupa a pavellons o altres espais de diferents ciutats del món, amb seguiments dels millors projectes en varietat d’acceleracions, entre elles un mes a Silicon Valley o altres premis.`,
                secondParagraph: `Els estudiants treballaran en equips seguint el <a href="https://imagine.cc/who-we-are/#steps" target="_blank">Mètode Lombard</a>, el procés d’aprenentatge radical d’<a href="http://imagine.cc" target="_blank">Imagine Creativity Center</a> basat en principis de Design Thinking i <a href="http://imaginedesigndoing.com" target="_blank">Design Doing</a>.`,
                thirdParagraph: `Cada esdeveniment comptarà al final de cada acte amb un equip guanyador i diversos equips finalistes. Un jurat serà l’encarregat de seleccionar les millors idees.`,
                fourthParagraph: `Els finalistes podran accelerar el seu projecte en edicions d’Imagine que es desenvoluparan pel món. Una de les acceleracions amb més repercussió és l’Imagine Silicon Valley, on els representants del millor equip de cada edició tindràn l’oportunitat de participar en un programa d’un mes d’acceleració a Silicon Valley, i desenvolupar i fer realitat la seva idea. Aquest programa consistira en una serie de visites a l’ecosistema únic de Silicon Valley, connectant i interactuant amb clients potencials i visitant les empreses més innovadores del món, inversors, socis estratègics i experts locals que els aportaran una experiència transformadora.`
            }
        },
        gallery: {
            es: 'Galería',
            en: 'Gallery',
            ca: 'Galeria'
        },
        seeVideo: {
            es: 'Ver vídeo',
            en: 'See video',
            ca: 'Veure vídeo'
        },
        participants: {
            es: 'participantes',
            en: 'participants',
            ca: 'participants'
        },
        team: {
            xavi: {
                es: 'Emprendedor en serie ubicado en Silicon Valley. Fundador de Imagine, TMT Factory & Innovalley. Stanford University, UPC',
                en: 'Serial entrepreneur located in Silicon Valley. Founder of Imagine, TMT Factory & Innovalley. Stanford University, UPC',
                ca: "Emprenedor en sèrie ubicat a Silicon Valley. Fundador d'Imagine, TMT Factory & Innovalley. Stanford University, UPC"
            },
            pau: {
                es: 'Ganador de 2 anillos NBA y 6 veces All-Star. Emprendió su carrera NBA con tan sólo 21 años convirtiéndose en jugador de equipos de la talla de los Memphis Grizzlies, los Ángeles Lakers, los Chicago Bulls y los San Antonio Spurs. Pau es considerado uno de los seis mejores jugadores en la historia de la NBA.',
                en: 'Winner of 2 NBA rings and 6 times All-Star. He started his NBA career with only 21 years becoming a team player of the likes of the Memphis Grizzlies, the Los Angeles Lakers, the Chicago Bulls and the San Antonio Spurs. Pau is considered one of the six best players in the history of the NBA.',
                ca: "Guanyador de 2 anells NBA i 6 vegades All-Star. Va emprendre la seva carrera NBA amb tan sols 21 anys convertint-se en jugador d'equips de la talla dels Memphis Grizzlies, Los Angeles Lakers, els Chicago Bulls i els San Antonio Spurs. Pau és considerat un dels sis millors jugadors en la història de la NBA."
            },
            marc: {
                es: 'Emprendedor. Fundador de SitMobile. <br/> Presidente AIJEC Joves Empresaris de Catalunya.Singularity University, EADA, UB.',
                en: 'Entrepreneur. Founder of SitMobile. <br/> President of AIJEC Joves Empresaris de Catalunya.Singularity University, EADA, UB.',
                ca: 'Emprenedor. Fundador de SitMobile. </br> President AIJEC Joves Empresaris de Catalunya.Singularity University, EADA, UB.'
            }
        },
        form: {
            name: {
                placeholder: {
                    es: 'Nombre',
                    en: 'Name',
                    ca: 'Nom'
                },
                inputRequiredErrorMessage: {
                    es: 'Por favor, introduzca su nombre',
                    en: 'Please enter your name',
                    ca: 'Si us plau, introdueixi el seu nom'
                }
            },
            email: {
                placeholder: {
                    es: 'Correo Electrónico',
                    en: 'Email',
                    ca: 'Correu Eletrònic'
                },
                inputRequiredErrorMessage: {
                    es: 'Por favor, introduzca su correo electrónico',
                    en: 'Please give an email adress',
                    ca: 'Si us plau, introdueixi el seu correu electrònic'
                },
                inputInvalidErrorMessage: {
                    es: 'El correo electrónico no es válido',
                    en: 'The email is not valid',
                    ca: 'El correu electrònic no és vàlid'
                }
            },
            phone: {
                placeholder: {
                    es: 'Teléfono',
                    en: 'Phone',
                    ca: 'Telèfon'
                },
                inputRequiredErrorMessage: {
                    es: 'Por favor, introduzca su teléfono',
                    en: 'Please, enter your phone ',
                    ca: 'Si us plau, introdueixi el seu telèfon'
                }
            },
            country: {
                placeholder: {
                    es: 'País',
                    en: 'Country',
                    ca: 'País'
                },
                inputRequiredErrorMessage: {
                    es: 'Por favor, introduzca su país',
                    en: 'Please, enter your country',
                    ca: 'Si us plau, introdueixi el seu país'
                }
            },
            company: {
                placeholder: {
                    es: 'Empresa',
                    en: 'Company',
                    ca: 'Empresa'
                }
            },
            submit: {
                text: {
                    es: 'Contáctanos',
                    en: 'Contact us',
                    ca: "Contacta'ns"
                },
                error: {
                    es: 'Lo sentimos la suscripción no se ha podido realizar, Vuelva a intentarlo más tarde',
                    en: 'We are sorry the subscription could not be made, please try again later',
                    ca: "Ho sentim la subscripció no s'ha pogut realitzar, Torneu a intentar-ho més tard"
                },
                success: {
                    es: 'Te has inscrito correctamente',
                    en: 'You have registered correctly',
                    ca: "T'has inscrit correctament"
                }
            }
        },
        moreThan: {
            es: 'más de ',
            en: 'more than ',
            ca: 'més de '
        },
        oviedoDate: {
            es: '3 de Abril ',
            en: 'April 3rd ',
            ca: `3 d'Abril`
        },
        ampostaDate: {
            es: '29 de Marzo ',
            en: 'March 29th ',
            ca: '29 de Març '
        },
        register: {
            es: "Inscríbete",
            ca: "Inscriu-te",
            en: "Register"
        }
    };
})();