export const UI = (function UI() {
    return {
        languageSelector: document.querySelector('.language-selector select'),
        navBar: document.querySelector('.navbar-nav'),
        dreambig: document.querySelector('.what-is-dreambig'),
        theProgram: document.querySelector('.description-dreambig'),
        galleryText: document.querySelectorAll('.gallery-text'),
        seeVideo: document.querySelectorAll('.see-video'),
        participants: document.querySelectorAll('.participants-text'),
        xaviInfoText: document.querySelector('.team-info-xavi'),
        pauInfoText: document.querySelector('.team-info-pau'),
        marcInfoText: document.querySelector('.team-info-marc'),
        organizeDreambig: document.querySelector('.organize-dreambig'),
        inputName: document.querySelector('input#name'),
        inputEmail: document.querySelector('input#email'),
        inputPhone: document.querySelector('input#phone'),
        inputCountry: document.querySelector('input#country'),
        inputCompany: document.querySelector('input#company'),
        sendMessageButton: document.querySelector('button#sendMessageButton'),
        signUpHere: document.querySelectorAll('a.sign-up-here'),
        nextDreambig: document.getElementById('next-editions-title'),
        moreThan: document.querySelectorAll('span.more-than'),
        oviedoDate: document.querySelector('.subtitle.oviedo span.date'),
        ampostaDate: document.querySelector('.subtitle.amposta span.date'),
        nextEdition: document.querySelectorAll(".next-dreambig-section .item .soon"),
        nextEditionDates: document.querySelectorAll("[data-translate-next-dreambig-dates]"),
        register: document.querySelectorAll(".know-more")
    };
})();